<template>
  <div class="BG01" id="BG01">
    <TabBar></TabBar>
    <div class="middle">
      <img
        src="../assets/Rectangle%2011.png"
        style="
          width: 344px;
          height: 40px;
          margin-top: 547px;
          margin-left: 370px;
        "
      />
      <img
        src="../assets/Rectangle%2014.png"
        style="position: absolute; top: 590px; left: 1070px"
      />
      <img
        src="../assets/Rectangle%2014.png"
        style="position: absolute; top: 605px; left: 1040px"
      />
      <img
        src="../assets/Rectangle%2014.png"
        style="position: absolute; top: 210px; left: 1130px"
      />
      <img
        src="../assets/Rectangle%2014.png"
        style="position: absolute; top: 615px; left: 430px"
      />
      <img
        src="../assets/Rectangle%2014.png"
        style="
          position: absolute;
          top: 655px;
          left: 400px;
          transform: scale(0.75);
          opacity: 0.5;
        "
      />
      <img
        src="../assets/Rectangle%2016.png"
        style="position: absolute; top: 385px; left: 1340px"
      />
      <img
        src="../assets/Rectangle%2016.png"
        style="
          position: absolute;
          top: 410px;
          left: 1310px;
          transform: scale(0.85);
          opacity: 0.5;
        "
      />
      <img
        src="../assets/Rectangle%2016.png"
        style="
          position: absolute;
          top: 510px;
          left: 1410px;
          transform: scale(0.85);
          opacity: 0.3;
        "
      />
      <img
        src="../assets/Rectangle%2016.png"
        style="
          position: absolute;
          top: 530px;
          left: 1390px;
          transform: scale(0.85);
          opacity: 0.3;
        "
      />
    </div>
  </div>
  <div class="BG02" id="BG02">
    <TabBar></TabBar>
    <div class="BG02-Log">
      <div class="BG02-Log-left">
        <img
          src="../assets/shandian.png"
          style="
            width: 15px;
            height: 20px;
            margin-top: 22px;
            margin-bottom: 5px;
          "
        />
        <p>DOWNLOAD</p>
        <p>App</p>
      </div>
      <div class="BG02-Log-right"></div>
    </div>
    <div class="BG02-Text">
      <p>MYENERGY</p>
    </div>
    <div
      style="
        margin-top: 428px;
        font-size: 160px;
        color: #424242;
        margin-left: 80px;
        line-height: 191px;
      "
    >
      <p>HEY</p>
      <p>LET’S TAP</p>
      <p>INTO OUR ENERGY</p>
    </div>
    <div class="BG02-Log2"></div>
  </div>
  <div class="BG03" id="BG03">
    <TabBar></TabBar>
    <div class="left">
      <p style="font-size: 40px; font-family: MyCustomFontBold, Sans-serif">
        欢迎来到Multiverse
      </p>
      <p style="margin-top: 74px">
        “这过程中给我带来更多的是学到了很多自己之前不会碰到的知识，困难麻烦当然很多，也是很正常的，多些经历总是好的，毕竟是创业。”
      </p>
      <p style="margin-top: 20px">- CEO William</p>
      <p style="margin-top: 85px">“我们那么相同却又那么不同，多有趣啊”</p>
      <p style="margin-top: 18px">- COO MIa</p>
    </div>
    <div class="right">
      <p style="">
        Multiverse<br />这个集体总是很重视每个人过程中的收获与成长，我们一起用心体会，一起经历许多，挖掘到了不一样的自己。成功是机率问题，而留下回忆是必然的，必然的才是我们更看重的。
        <br />
        <br />
        -《我的能量》是团队开发的app -《Mightme》是我们创立的潮流文化品牌
        我们想通过我们的产品表达我们这群人的想法和自我喜好，在这个过程中很开心遇到了你！
      </p>
    </div>
  </div>
  <div class="BG04">
    <h1 style="margin-top: 83px; font-size: 40px">Email</h1>
    <p style="margin-top: 7px; font-size: 28px">
      业务咨询: <span style="font-size: 34px">multiverse.china#gmail.com</span>
    </p>
    <p style="margin-top: 18px; font-size: 22px; color: #838383">
      *为了能给您提供更好的服务，请先将#号替换成@然后通过邮箱联系我们。
    </p>
    <h1 style="margin-top: 52px; font-size: 40px">Tel</h1>
    <p style="margin-top: 7px; font-size: 34px; margin-bottom: 95px">
      （+86）17778146273
    </p>
    <!--    <h1 style="margin-top: 60px">微信公众号</h1>
        <div class="code"></div>
        <div class="beian"></div>-->
  </div>
</template>

<script>
import TabBar from "@/components/TabBar.vue";

export default {
  components: {
    TabBar
  }
};
</script>

<style scoped>
* {
  padding: 0px;
  margin: 0px;
}

.BG01 {
  background-color: #131313;
  height: 990px;
  width: 100%;
  background-image: url("~@/assets/BG01.png");
}

p {
  font-family: "MyCustomFont", Sans-serif;
}

h1 {
  font-family: "MyCustomFont", Sans-serif;
}

.middle {
  height: 990px;
  width: 990px;
  background-image: url("~@/assets/BG02.png");
  background-repeat: no-repeat;
  margin: 0px auto;
}

.BG02 {
  background-color: #131313;
  height: 990px;
  width: 100%;
  position: relative;
  padding-top: 20px;
}

.BG02-Log {
  width: 195px;
  height: 100px;
  border: 1px solid #a429ec;
  border-radius: 10px;
  margin-top: 207px;
  margin-left: 80px;
  display: flex;
  float: left;
}

.BG02-Log-left {
  text-align: center;
  width: 50%;
  height: 100%;
  color: #ffffff;
  font-size: 12px;
  justify-content: center;
}

.BG02-Log-right {
  width: 50%;
  height: 100%;
  float: right;
  background-color: #ffffff;
  transform: scale(0.95);
  border-radius: 10px;
}

.BG02-Text {
  font-size: 140px;
  float: left;
  margin-top: 158px;
  margin-left: 27px;
  background-image: linear-gradient(to right, #681ee8, #e434f0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.BG02-Log2 {
  width: 717px;
  height: 645px;
  z-index: 9999999;
  position: absolute;
  top: 333px;
  left: 776px;
  right: 43px;
  bottom: 42px;
  background-image: url("~@/assets/yangji.png");
}

.BG03 {
  background-color: #131313;
  height: 990px;
  width: 100%;
  border-radius: 0 0 20px 30px;
  display: flex;
}

.BG04 {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  text-align: center;
}

.code {
  width: 144px;
  height: 144px;
  background-color: #d9d9d9;
  margin: 26px auto;
}

.beian {
  width: 321px;
  height: 38px;
  background-image: url("~@/assets/beian.png");
  margin: 74px auto;
}

.left {
  width: 536px;
  height: 60%;
  margin-top: 200px;
  color: #ffffff;
  font-size: 22px;
  font-family: "MyCustomFont", Sans-serif;
  margin-left: 80px;
  line-height: 48px;
}

.right {
  width: 562px;
  height: 60%;
  margin-top: 320px;
  color: #ffffff;
  font-size: 22px;
  font-family: "MyCustomFont", Sans-serif;
  line-height: 48px;
  margin-left: 416px;
}
</style>
