import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import {TransitionGroup} from "vue";
import { TroisJSVuePlugin } from 'troisjs';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/assets/fonts/font.css';

createApp(App).use(router).mount('#app')

const app = createApp(App);
app.component('TransitionGroup',TransitionGroup);
app.use(TroisJSVuePlugin);
app.use(ElementPlus)

