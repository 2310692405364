import {createRouter, createWebHashHistory} from 'vue-router'

import HomeView from '../views/HomeView.vue'

const routes = [
    {
        path: '/',
        redirect: '/HomeView'
    },
    {
        path: '/HomeView',
        name: 'HomeView',
        component: HomeView
    },
]

const router = createRouter({
    mode: 'history',
    history: createWebHashHistory(),
    routes
})

export default router
