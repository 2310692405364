<template>
  <div id="app">
    <RouterView/>
  </div>
</template>

<script>

export default {
  name: 'app',
  components: {}
}

</script >


<style>
* {
  margin: 0px;
  padding: 0px;
}

#app {

}

</style>
