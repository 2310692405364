<template>
  <div class="Box">
    <div class="log-o">
      <img src="../assets/logo.png" style="width: 38px;height: 37px">
    </div>
    <ul>
      <li>
        <a href="#" data-target="BG03" @click="scrollToTarget">ABOUT US</a>
      </li>
      <li>
        <a href="" data-target="BG02" @click="scrollToTarget">PRODUCT</a>
      </li>
      <li>
        <a href="" data-target="BG01" @click="scrollToTarget">HOME</a>
      </li>
    </ul>
  </div>
</template>

<style>
* {
  padding: 0px;
  margin: 0px;
}

a {
  color: #ffffff;
  text-decoration: none;
}

.Box {
  display: flex;
  align-items: center;
  position: absolute;
}

ul {
  margin-left: 905px;
  margin-top: 40px;
}

ul li {
  float: right;
  color: #ffffff;
  list-style: none;
  margin-right: 100px;
  font-size: 28px;
  font-family: "MyCustomFont", Sans-serif;
}

.log-o {
  width: 48px;
  height: 48px;
  background-color: #ffffff;
  border-radius: 45px;
  margin-left: 80px;
  margin-top: 40px;
  text-align: center;
}
</style>

<script>
export default {
  name: 'TabBar',
  components: {},
  methods: {
    scrollToTarget(event) {
      event.preventDefault();
      const target = event.target.dataset.target;
      const element = this.$root.$el.querySelector(`#${target}`);
      if (element) {
        element.scrollIntoView({behavior: 'smooth'});
      }
    }
  }
}
</script>


